import { IPaginatedData } from '@/modules/common/pagination.interface';
import { ISearchableScan } from '@connect-saas/types';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';

export default class Scan {
  private readonly client: ClientInterface;

  private readonly resource: string = 'scan';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getScansByDate(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableScan>>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IPaginatedData<ISearchableScan>>(builder);
  }
}
