// eslint-disable-next-line import/order
import { GetterTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { GeographyState } from './types';
import { IScanLocationCount, ISearchableScan } from '@connect-saas/types';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<GeographyState, IRootState> = {
  scans_by_location(state): IScanLocationCount[] {
    return state.scans_by_location;
  },
  scans_by_date(state): ISearchableScan {
    return state.scans_by_date;
  }
};
