/* eslint-disable import/prefer-default-export */
import { IAnalyticsScanCountsStat } from '@/modules/analytics/technology/technology.interface';
import { MutationTree } from 'vuex';
import { TechnologyState } from './types';

export const mutations: MutationTree<TechnologyState> = {
  UPDATE_TECHNOLOGY_BROWSER_LIST(
    state: TechnologyState,
    technology_browser_list: IAnalyticsScanCountsStat[]
  ) {
    state.technology_browser_list = technology_browser_list;
  },
  UPDATE_TECHNOLOGY_OS_LIST(
    state: TechnologyState,
    technology_os_list: IAnalyticsScanCountsStat[]
  ) {
    state.technology_os_list = technology_os_list;
  }
};
