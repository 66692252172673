import { EGranularity } from '@/modules/common/common.enum';
import { EDimensions, EMetrics, EScanType } from '@connect-saas/types';
import ClientInterface from '../common/ClientInterface';

export default class AnalyticsBuilder {
  private resource: string;

  private client: ClientInterface;

  constructor(resource: string, client: ClientInterface) {
    this.resource = resource;
    this.client = client;
  }

  checkSeparator() {
    return this.resource.indexOf('?') > -1 ? '&' : '?';
  }

  setFrom(from: string | Date): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}from=${from}`;
    return this;
  }

  setTo(to: string | Date): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}to=${to}`;
    return this;
  }

  setMetrics(metrics: string | EMetrics[]): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}metrics=${metrics}`;
    return this;
  }

  setFilter(filterKey: string, filterValue: string): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}filter.${filterKey}=${filterValue}`;
    return this;
  }

  setBoundingBox(boundingBox: string): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}boundingBox=${boundingBox}`;
    return this;
  }

  setPrecision(precision: number): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}precision=${precision}`;
    return this;
  }

  setGranularity(granularity: EGranularity): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}granularity=${granularity}`;
    return this;
  }

  setDimensions(dimensions: string | EDimensions[]): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}dimensions=${dimensions}`;
    return this;
  }

  setTop(top: number): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}top=${top}`;
    return this;
  }

  setScanType(scanType: EScanType): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}scanType=${scanType}`;
    return this;
  }

  setLines(lines: string): AnalyticsBuilder {
    const separator = this.checkSeparator();
    this.resource += `${separator}lines=${lines}`;
    return this;
  }

  async get<T>(): Promise<T> {
    const response = await this.client.get<T>(this.resource);
    return response;
  }
}
