<script setup lang="ts">
import useWebSocket from '@/composables/useWebSocket';
import { useFavicon } from '@vueuse/core';
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRegisterSW } from 'virtual:pwa-register/vue';

const store = useStore();
store.commit('GET_HOST_NAME');

const isEcocanHost = computed(() => store.state.is_ecocan_host);
const changeFavicon = () => {
  const icon = isEcocanHost.value ? 'ecocan.ico' : 'favicon.ico';
  useFavicon(icon, { baseUrl: '/' });
};

const { needRefresh, updateServiceWorker } = useRegisterSW();
const updateMessage = ref('New version available, click to update.');

let websocketUrl = import.meta.env.VITE_APP_WEBSOCKET_URL;

const currentUrl = new URL(window.location.href);
if (currentUrl.host === 'dashboard.staging.connectqr.ai') {
  websocketUrl = 'wss://dashboard.staging.connectqr.ai/wss';
}

useWebSocket(websocketUrl, store);

onMounted(() => {
  changeFavicon();
});
</script>

<template>
  <div class="application theme--light" id="root">
    <v-app>
      <router-view v-slot="{ Component }">
        <Transition name="fade" mode="out-in">
          <component :is="Component" />
        </Transition>
      </router-view>
      <v-snackbar timeout="-1" bottom v-model="needRefresh">
        {{ updateMessage }}
        <template #actions>
          <v-btn color="sheen-green-darken-1" text @click="updateServiceWorker">Update</v-btn>
        </template>
      </v-snackbar>
    </v-app>
  </div>
</template>

<style lang="scss">
.v-application a {
  color: rgb(var(--v-theme-primary));
}
</style>
