export const youtubeEmbedLink = (url) => {
  const isYouTubeEmbedLinkRegex =
    /(?:https:\/\/(?:www\.)?(youtube(?:-nocookie)?\.com\/.*|youtu\.be\/.*))/;
  return isYouTubeEmbedLinkRegex.test(url);
};

export const extractYouTubeInfo = (url: string) => {
  let videoId = null;
  let startTime = null;
  let noCookie = null;
  const noCookieLink = 'https://www.youtube-nocookie.com';
  const youtubeUrl = url.replace(/&amp;/g, '&');

  // Regular expression to match YouTube URLs
  const regexList = [
    /(?:https?:\/\/(?:www\.)?youtube\.com\/(?:watch\?v=|v\/))([^&?\\/]+)/,
    /(?:https?:\/\/(?:www\.)?youtube(?:-nocookie)?\.com\/embed(\/)?)([^]+)/,
    /(?:https?:\/\/(?:www\.)?youtu\.be\/)([^&?\\/]+)/
  ];
  regexList.some((item) => {
    const match = youtubeUrl.match(item);
    if (match) {
      const urlParams = new URLSearchParams(youtubeUrl.split('?')[1]);
      videoId = urlParams.get('v') || match[match.length - 1].split('?')[0]; // Extract video id
      startTime = urlParams.get('t') || urlParams.get('start') || null; // Extract start time if available
      noCookie = youtubeUrl.includes(noCookieLink) ? noCookieLink : null; // Check nocookie
      return true;
    }
    return false;
  });

  return { videoId, startTime, noCookie };
};
