import {
  IAnalyticsScanCountsStatResults,
  IAnalyticsScanStat
} from '@/modules/analytics/technology/technology.interface';
import { ITimeSeries } from '@/modules/common/service.type';
import ClientInterface from '@/services/api/common/ClientInterface';
import { IAnalyticsPayload } from '@/views/private/dashboard/types/interfaces';
import {
  IAnalyticsScanCountsV3Query,
  IAnalyticsScanMetrics,
  IPopularProduct,
  IScanLocationCount
} from '@connect-saas/types';
import AnalyticsBuilder from './analytics/AnalyticsBuilder';

export default class ScanAnalytics {
  private readonly client: ClientInterface;

  private readonly resource: string = 'analytics/scan';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  getAnalyticsScanStat(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(`${this.resource}/metrics`, this.client)
  ): Promise<IAnalyticsScanMetrics> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setMetrics(payload.metrics);
    if (payload.scanType) {
      builder.setScanType(payload.scanType);
    }
    if (payload.id) {
      builder.setFilter(`${payload.analyticsType}Id`, payload.id);
    }
    return builder.get<IAnalyticsScanMetrics>();
  }

  getAnalyticsScanDimensions(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(`${this.resource}/dimensions`, this.client)
  ): Promise<{ dimensions: IAnalyticsScanStat }> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setDimensions(payload.dimensions);
    if (payload.scanType) {
      builder.setScanType(payload.scanType);
    }
    if (payload.top) {
      builder.setTop(payload.top);
    }
    if (payload.id) {
      builder.setFilter(`${payload.analyticsType}Id`, payload.id);
    }
    return builder.get<{ dimensions: IAnalyticsScanStat }>();
  }

  getAnalyticsScanTopProducts(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(`${this.resource}/top-products`, this.client)
  ): Promise<{ data: IPopularProduct[] }> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    if (payload.scanType) {
      builder.setScanType(payload.scanType);
    }
    if (payload.id) {
      builder.setFilter(`${payload.analyticsType}Id`, payload.id);
    }
    return builder.get<{ data: IPopularProduct[] }>();
  }

  getAnalyticsScanTimeSeries(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(`${this.resource}/time-series`, this.client)
  ): Promise<ITimeSeries> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setLines(payload.lines);
    if (payload.granularity) {
      builder.setGranularity(payload.granularity);
    }
    if (payload.scanType) {
      builder.setScanType(payload.scanType);
    }
    if (payload.id) {
      builder.setFilter(`${payload.analyticsType}Id`, payload.id);
    }
    return builder.get<ITimeSeries>();
  }

  getScannedAtByLocation(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(`${this.resource}/locations`, this.client)
  ): Promise<{ locations: IScanLocationCount[] }> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setBoundingBox(payload.boundingBox);
    if (payload.scanType) {
      builder.setScanType(payload.scanType);
    }
    if (payload.id) {
      builder.setFilter(`${payload.analyticsType}Id`, payload.id);
    }
    return builder.get<{ locations: IScanLocationCount[] }>();
  }

  getAnalyticsScanReports(
    payload: IAnalyticsScanCountsV3Query,
    builder: AnalyticsBuilder = new AnalyticsBuilder(`${this.resource}/report`, this.client)
  ): Promise<IAnalyticsScanCountsStatResults> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setDimensions(payload.dimensions);
    if (payload.metrics) {
      builder.setMetrics(payload.metrics);
    }
    if (payload.scanType) {
      builder.setScanType(payload.scanType);
    }
    return builder.get<IAnalyticsScanCountsStatResults>();
  }
}
