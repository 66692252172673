import {
  ICampaign,
  IProductCampaignStats
} from '@/modules/analytics/analytics/analytics.interface';
import { EGranularity } from '@/modules/common/common.enum';
import { TPayload } from '@/modules/common/common.type';
import { EAnalyticsType } from '@/modules/common/service.enum';
import { ITech } from '@/modules/common/service.interface';
import { IProduct } from '@/modules/product/product.interface';
import ClientInterface from '@/services/api/common/ClientInterface';
import ResourceResponse from './common/ResourceResponse';

export default class Analytics {
  private readonly client: ClientInterface;

  private readonly resource: EAnalyticsType;

  constructor(client: ClientInterface, resource: EAnalyticsType) {
    this.client = client;
    this.resource = resource;
  }

  getTechById(id: string | number): Promise<ResourceResponse<ITech>> {
    return this.client.getById(this.resource, id);
  }

  getProductById(id: string | number): Promise<ResourceResponse<IProduct>> {
    return this.client.getById(this.resource, id);
  }

  getCampaignById(id: string): Promise<ResourceResponse<ICampaign>> {
    return this.client.getById(`v2/${this.resource}`, id);
  }

  getAnalyticsCampaignStats(
    payload: TPayload<EGranularity>
  ): Promise<ResourceResponse<IProductCampaignStats[]>> {
    return this.client.get(
      `${this.resource}/${payload.id}/campaign-stats?from=${payload.from}&to=${payload.to}`
    );
  }
}
