import { EAnalyticsType } from '@/modules/common/service.enum';
import { TQrType } from '@/modules/qr-generator/link-config.interface';
import { ETechBatchType } from '@/modules/tech-batch/tech-batch.enum';
import Company from '@/services/api/Company';
import { TIntegrationType } from '@/views/private/integration/types/type';
import Account from './Account';
import Analytics from './Analytics';
import AnalyticsReport from './AnalyticsReport';
import AnalyticsScan from './AnalyticsScan';
import AudienceTable from './Audience';
import Authentication from './Authentication';
import BrandProtection from './BrandProtection';
import Campaign from './Campaign';
import DashboardCampaign from './DashboardCampaign';
import Demography from './Demography';
import Email from './Email';
import EngagedProduct from './EngagedProduct';
import Form from './Form';
import Geography from './Geography';
import Integration from './Integration';
import Link from './Link';
import Lookup from './Lookup';
import Media from './Media';
import Metadata from './Metadata';
import ProductV2 from './NewProduct';
import Notification from './Notification';
import Page from './Page';
import Product from './Product';
import Profile from './Profile';
import Qr from './Qr';
import Report from './Report';
import Scan from './Scan';
import ShortUrl from './ShortUrl';
import Signup from './Signup';
import TechBatch from './TechBatch';
import Technology from './Technology';
import TrackTrace from './TrackTrace';
import User from './User';
import Client from './common/Client';

export default class ApiFactory {
  private readonly CLIENT: Client;

  private readonly CLIENT_V1: Client;

  private readonly CLIENT_V2: Client;

  private readonly CLIENT_V3: Client;

  private readonly CLIENT_AUTH: Client;

  private readonly API_PATH = `${import.meta.env.VITE_APP_CONNECT_NEXT_API}`;

  constructor() {
    this.CLIENT = new Client(this.API_PATH);
    this.CLIENT_V1 = new Client(`${this.API_PATH}v1`);
    this.CLIENT_AUTH = new Client(`${this.API_PATH}v1`, false);
    this.CLIENT_V2 = new Client(`${this.API_PATH}v2`);
    this.CLIENT_V3 = new Client(`${this.API_PATH}v3`);
  }

  getCompany(): Company {
    return new Company(this.CLIENT);
  }

  getProducts(): Product {
    return new Product(this.CLIENT);
  }

  getGeography(): Geography {
    return new Geography(this.CLIENT);
  }

  getDemography(): Demography {
    return new Demography(this.CLIENT);
  }

  getEngagementProducts(): EngagedProduct {
    return new EngagedProduct(this.CLIENT);
  }

  getEngagementCampaigns(): DashboardCampaign {
    return new DashboardCampaign(this.CLIENT);
  }

  getTechnology(): Technology {
    return new Technology(this.CLIENT_V2);
  }

  getScan(): Scan {
    return new Scan(this.CLIENT_V3);
  }

  getAudience(): AudienceTable {
    return new AudienceTable(this.CLIENT);
  }

  getReport(): Report {
    return new Report(this.CLIENT);
  }

  getBrandProtection(): BrandProtection {
    return new BrandProtection(this.CLIENT);
  }

  getAnalytics(resource: EAnalyticsType): Analytics {
    return new Analytics(this.CLIENT, resource);
  }

  getAnalyticsReport(): AnalyticsReport {
    return new AnalyticsReport(this.CLIENT);
  }

  getProductsV2(): ProductV2 {
    return new ProductV2(this.CLIENT_V2);
  }

  getMedia(): Media {
    return new Media(this.CLIENT);
  }

  getAuthentication(): Authentication {
    return new Authentication(this.CLIENT_AUTH);
  }

  getUser(): User {
    return new User(this.CLIENT_V1);
  }

  getAccount(): Account {
    return new Account(this.CLIENT_V1);
  }

  getProfile(): Profile {
    return new Profile(this.CLIENT_V1);
  }

  getTechBatch(techType: ETechBatchType): TechBatch {
    return new TechBatch(this.CLIENT_V1, techType);
  }

  getLookup(): Lookup {
    return new Lookup(this.CLIENT_V1);
  }

  getTech(): Qr {
    return new Qr(this.CLIENT_V2);
  }

  getCampaign(): Campaign {
    return new Campaign(this.CLIENT_V2);
  }

  getTrackTrace(): TrackTrace {
    return new TrackTrace(this.CLIENT_V1);
  }

  getMetadata(): Metadata {
    return new Metadata(this.CLIENT_V1);
  }

  getNotifications(): Notification {
    return new Notification(this.CLIENT_V1);
  }

  getForm(): Form {
    return new Form(this.CLIENT_V1);
  }

  getEmail(): Email {
    return new Email(this.CLIENT_V1);
  }

  getSignUp(): Signup {
    return new Signup(this.CLIENT_V1);
  }

  getLink(qrType?: TQrType): Link {
    return new Link(this.CLIENT_V1, qrType);
  }

  getIntegration(type?: TIntegrationType): Integration {
    return new Integration(this.CLIENT_V1, type);
  }

  getShortUrl(): ShortUrl {
    return new ShortUrl(this.CLIENT_V1);
  }

  getPage(): Page {
    return new Page(this.CLIENT_V1);
  }

  getAnalyticsScan(): AnalyticsScan {
    return new AnalyticsScan(this.CLIENT_V3);
  }
}
