// eslint-disable-next-line import/order
import { IRootState } from '@/modules/common/common.interface';
import { Module } from 'vuex';
import { actions } from './actions';
import { mutations } from './mutations';
import { TechnologyState } from './types';

const state: TechnologyState = {
  technology_browser_list: null,
  technology_os_list: null
};

// eslint-disable-next-line import/prefer-default-export
export const technology: Module<TechnologyState, IRootState> = {
  state,
  mutations,
  actions
};
