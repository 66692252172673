import { TFilter } from '@/modules/common/service.type';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';

export default (builder: PaginationBuilder): TFilter => {
  const filterOperatorMap = {
    EQ: '$eq',
    IN: '$in',
    GTE: '$gte',
    GT: '$gt',
    LTE: '$lte',
    LT: '$lt',
    BTW: '$btw'
  };

  // Build Filters
  const filter: Record<string, string | Array<string>> = {};
  builder.getFilters().forEach((builderFilter) => {
    const { field, operator, value } = builderFilter;

    if (!operator) {
      // Handle case where no operator is provided
      const valueString = Array.isArray(value) ? value.join(',') : value;
      filter[field] = valueString;
      return;
    }
    // Check the Operator is in the map (it's valid)
    if (!filterOperatorMap[operator]) {
      return;
    }

    // Map the operator to the one the API requires
    const mappedOperator = filterOperatorMap[operator];

    // Build a comma separated string if the filter value is an array
    const valueString = Array.isArray(value) ? value.join(',') : value;

    // Create the filter string
    const filterString = `${mappedOperator}:${valueString}`;

    // Add the filter string to the filters
    if (!filter[field]) {
      // No filter exists for this field so add it
      filter[field] = filterString;
    } else if (Array.isArray(filter[field])) {
      // Array of filters exists for this field already so add filter string to the array
      (filter[field] as string[]).push(filterString);
    } else {
      // Single filter (no array) exists for this field, so convert to array and add new filter string
      filter[field] = [filter[field] as string, filterString];
    }
  });
  return filter;
};
