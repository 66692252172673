import { ActionContext, ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { EFilterOperators } from '@/modules/common/pagination.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IPageState } from './types';
import { ISearchablePage } from '@connect-saas/modules/search';
import { IPaginatedData } from '@connect-saas/util/common';
import { IPage, IPageCreate, IPageUpdate } from '@connect-saas/types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IPageState, IRootState> = {
  GET_PAGINATED_PAGES({ commit, dispatch }: ActionContext<IPageState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload?.sort?.field) {
        builder.setSortBy(payload.sort.field, payload.sort.direction);
      }
      builder.setSearch(payload.search ?? '');
      builder.clearFilter();
      if (payload.filter) {
        payload.filter.forEach((element) => {
          builder.addFilter(element.selectedFilter, EFilterOperators.EQ, element.value);
        });
      }
      ApiFactory.getPage()
        .getPaginatedPages(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchablePage>>) => {
          commit('UPDATE_PAGINATED_PAGES', response);
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  GET_PAGE_BY_ID({ commit, dispatch }: ActionContext<IPageState, IRootState>, id: string) {
    return new Promise((resolve, reject) => {
      ApiFactory.getPage()
        .getPageById(id)
        .then((response: ResourceResponse<IPage | null>) => {
          commit('SELECTED_PAGE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  CREATE_PAGE({ commit, dispatch }: ActionContext<IPageState, IRootState>, payload: IPageCreate) {
    return new Promise((resolve, reject) => {
      ApiFactory.getPage()
        .createPage(payload)
        .then((response: ResourceResponse<IPage>) => {
          commit('UPDATE_SELECTED_PAGE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  UPDATE_SELECTED_PAGE(
    { commit, dispatch }: ActionContext<IPageState, IRootState>,
    payload: { id: string; body: IPageUpdate }
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getPage()
        .updatePage(payload.id, payload.body)
        .then((response: ResourceResponse<IPage>) => {
          commit('UPDATE_SELECTED_PAGE', response.resource);
          resolve(response.resource);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  DELETE_SELECTED_PAGE(
    { dispatch }: ActionContext<IPageState, IRootState>,
    id: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      ApiFactory.getPage()
        .deletePage(id)
        .then((response) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  },
  DUPLICATE_SELECTED_PAGE(
    { dispatch }: ActionContext<IPageState, IRootState>,
    id: string
  ): Promise<ResourceResponse<IPage>> {
    return new Promise((resolve, reject) => {
      ApiFactory.getPage()
        .duplicatePage(id)
        .then((response: ResourceResponse<IPage>) => {
          resolve(response);
        })
        .catch((errorResponse: unknown) => {
          if (errorResponse instanceof Error) {
            dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
            reject(errorResponse);
          }
        });
    });
  }
};
