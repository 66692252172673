import { IAnalyticsScanCountsStatResults } from '@/modules/analytics/technology/technology.interface';
import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
// eslint-disable-next-line import/order
import { ActionTree } from 'vuex';
import { TechnologyState } from './types';

const ApiFactory = new Api();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<TechnologyState, IRootState> = {
  GET_PAGINATED_TECHNOLOGY_LIST({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanReports(payload)
        .then((response: IAnalyticsScanCountsStatResults) => {
          const dimensionList = [
            { type: 'browser', mutation: 'UPDATE_TECHNOLOGY_BROWSER_LIST' },
            { type: 'os', mutation: 'UPDATE_TECHNOLOGY_OS_LIST' }
          ];
          dimensionList.forEach((item) => {
            if (payload.dimensions.includes(item.type)) {
              commit(item.mutation, response.data);
            }
          });
          resolve(response.data);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  }
};
