export enum EFeature {
  BRAND_PROTECTION = 'brandProtection',
  TRACK_AND_TRACE = 'trackAndTrace',
  MOTIFMICRO = 'motifmicro',
  TRACER = 'tracer',
  PROTECTCODE = 'protectCode',
  PROTECTCODE_PLUS = 'protectCodePlus',
  FORMS = 'forms',
  PAGES = 'pages',
  PRODUCTS = 'products',
  CAMPAIGNS = 'campaigns',
  USERS = 'users',
  COMPANIES = 'companies',
  CONNECT_QR = 'connectQr',
  ANALYTICS_REPORTS = 'analyticsReports',
  ANALYTICS_LINK_SCANNED_AT = 'analyticsLinkScannedAt',
  ANALYTICS_LINK_LOCATIONS = 'analyticsLinkLocations',
  ANALYTICS_LINK_BY_METRICS = 'analyticsLinkByMetrics',
  ANALYTICS_LINK_TIME_SERIES = 'analyticsLinkTimeSeries',
  ANALYTICS_LINK_BY_DIMENSIONS = 'analyticsLinkByDimensions',
  ANALYTICS_LINK_TOP_PERFORMING = 'analyticsLinkTopPerforming',
  AI_QR_CODE = 'aiQrCode',
  PUBLIC_API = 'publicApi',
  INTEGRATION = 'integration',
  PAGE_THEME = 'pageTheme',
}
