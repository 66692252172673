import { IAllAiQrResponse, ICreateAiQr } from '@/modules/aiqr/aiqr.interface';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import { TQrType } from '@/modules/qr-generator/link-config.interface';
import {
  IAnalyticsPayload,
  ILinkTagResults,
  ISearchableLink
} from '@/modules/qr-generator/link.interface';
import { TConnectQrAction } from '@/modules/qr-generator/link.type';
import {
  IAiQr,
  ILinkConnectQr,
  ILinkConnectQrAllowance,
  ILinkConnectQrStats,
  ILinkScanMetricsAnalytics,
  ILinkScanOverTimeCount,
  ILinkScanPopularAnalytics,
  ILinkScanPopularTermsData,
  ILinkScannedAtAnalytics,
  ILinkStaticQr,
  IScanLocationCount,
  ITopScannedLinkResponse,
  ITopScannedShortLinkResponse
} from '@connect-saas/types';
import ClientInterface from './common/ClientInterface';
import PaginatedResponse from './common/PaginatedResponse';
import PaginationBuilder from './common/PaginationBuilder';
import ResourceResponse from './common/ResourceResponse';
import AnalyticsBuilder from './analytics/AnalyticsBuilder';

export default class Link {
  private readonly client: ClientInterface;

  private readonly type: TQrType;

  private readonly resource: string;

  constructor(client: ClientInterface, type: TQrType) {
    this.client = client;
    this.type = type;
    this.resource = 'link';
  }

  /**
   * Search API for Static, Dynamic, and Short URLs.
   *
   * @param builder - The pagination builder for configuring the search.
   * @returns A paginated response containing the searchable links.
   */
  getAllPaginatedLinks(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchableLink>>> {
    builder.setResource(`/link/search`);
    return this.client.getPaginated<IPaginatedData<ISearchableLink>>(builder);
  }

  getLinkById<T>(id: string): Promise<ResourceResponse<T>> {
    return this.client.getById(`${this.resource}/${this.type}`, id);
  }

  createLink<T, G>(body: T): Promise<ResourceResponse<G>> {
    return this.client.create<T, G>(`${this.resource}/${this.type}`, body);
  }

  updateLink<T, G>(id: string, body: T): Promise<ResourceResponse<G>> {
    return this.client.patch(`${this.resource}/${this.type}`, body, id);
  }

  deleteLinkById(id: string): Promise<void> {
    return this.client.delete(`${this.resource}/${this.type}`, id);
  }

  getScannedAt(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(
      `${this.resource}/analytics/scanned-at`,
      this.client
    )
  ): Promise<ILinkScannedAtAnalytics> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    if (payload.filter) {
      builder.setFilter('id', payload.filter);
    }
    if (payload.type) {
      builder.setFilter('type', payload.type);
    }
    return builder.get<ILinkScannedAtAnalytics>();
  }

  getScannedAtByMetrics(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(
      `${this.resource}/analytics/metrics`,
      this.client
    )
  ): Promise<ILinkScanMetricsAnalytics> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setMetrics(payload.metrics);
    if (payload.filter) {
      builder.setFilter('id', payload.filter);
    }
    if (payload.type) {
      builder.setFilter('type', payload.type);
    }
    return builder.get<ILinkScanMetricsAnalytics>();
  }

  getScannedAtByLocation(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(
      `${this.resource}/analytics/locations`,
      this.client
    )
  ): Promise<IScanLocationCount[]> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setBoundingBox(payload.boundingBox);
    if (payload.filter) {
      builder.setFilter('id', payload.filter);
    }
    if (payload.type) {
      builder.setFilter('type', payload.type);
    }
    return builder.get<IScanLocationCount[]>();
  }

  getScanTimeSeries(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(
      `${this.resource}/analytics/time-series`,
      this.client
    )
  ): Promise<ILinkScanOverTimeCount> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setGranularity(payload.granularity);
    if (payload.filter) {
      builder.setFilter('id', payload.filter);
    }
    if (payload.type) {
      builder.setFilter('type', payload.type);
    }
    return builder.get<ILinkScanOverTimeCount>();
  }

  getPopularScansByDimensions(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(
      `${this.resource}/analytics/popular`,
      this.client
    )
  ): Promise<ILinkScanPopularAnalytics> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setDimensions(payload.dimensions);
    builder.setTop(payload.top);
    if (payload.filter) {
      builder.setFilter('id', payload.filter);
    }
    if (payload.type) {
      builder.setFilter('type', payload.type);
    }
    return builder.get<ILinkScanPopularAnalytics>();
  }

  getScannedAtByLocationCountries(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(
      `${this.resource}/analytics/locations/countries`,
      this.client
    )
  ): Promise<ILinkScanPopularTermsData> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    if (payload.filter) {
      builder.setFilter('id', payload.filter);
    }
    if (payload.type) {
      builder.setFilter('type', payload.type);
    }
    return builder.get<ILinkScanPopularTermsData>();
  }

  getScanTopLinks(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(
      `${this.resource}/analytics/top-links`,
      this.client
    )
  ): Promise<{ data: ITopScannedLinkResponse[] }> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setTop(payload.top);
    if (payload.filter) {
      builder.setFilter('id', payload.filter);
    }
    return builder.get<{ data: ITopScannedLinkResponse[] }>();
  }

  getConnectQrStats(): Promise<ILinkConnectQrStats> {
    return this.client.get(`${this.resource}/connect-qr/stats`);
  }

  getConnectQrAllowance(): Promise<ILinkConnectQrAllowance> {
    return this.client.get(`${this.resource}/connect-qr/allowance`);
  }

  getLinkTags(): Promise<ILinkTagResults> {
    return this.client.get(`${this.resource}/tag`);
  }

  pause(payload: { id: string; action: TConnectQrAction }): Promise<ResourceResponse<void>> {
    return this.client.post(`${this.resource}/${this.type}/${payload.id}/${payload.action}`);
  }

  generateAiQr(body: ICreateAiQr): Promise<ResourceResponse<IAiQr>> {
    return this.client.post(`${this.resource}/${this.type}/${body.linkId}/ai-qr-code`, body.config);
  }

  deleteAiQr(payload: { linkId: string; id: string }): Promise<void> {
    return this.client.delete(
      `${this.resource}/${this.type}/${payload.linkId}/ai-qr-code`,
      payload.id
    );
  }

  getAllAiQr(linkId: string): Promise<IAllAiQrResponse> {
    return this.client.get(`${this.resource}/${this.type}/${linkId}/ai-qr-code`);
  }

  duplicate(linkId: string): Promise<ResourceResponse<ILinkStaticQr | ILinkConnectQr>> {
    return this.client.post(`${this.resource}/${this.type}/${linkId}/duplicate`);
  }

  getScanTopShortLinks(
    payload: IAnalyticsPayload,
    builder: AnalyticsBuilder = new AnalyticsBuilder(
      `${this.resource}/analytics/top-short-urls`,
      this.client
    )
  ): Promise<{ data: ITopScannedShortLinkResponse[] }> {
    builder.setFrom(payload.from);
    builder.setTo(payload.to);
    builder.setTop(payload.top);
    return builder.get<{ data: ITopScannedShortLinkResponse[] }>();
  }
}
