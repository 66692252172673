import { ActionTree } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import GeohashScansBuilder from '@/services/api/common/GeohashScansBuilder';
import { GeographyState } from './types';
import { IScanLocationCount } from '@connect-saas/types';
import { EFilterOperators } from '@/modules/common/pagination.interface';

const ApiFactory = new Api();
const builder = new GeohashScansBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<GeographyState, IRootState> = {
  GET_SCANS_BY_LOCATION({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getScannedAtByLocation(payload)
        .then((response: { locations: IScanLocationCount[] }) => {
          commit('UPDATE_SCANS_BY_LOCATION', response.locations);
          resolve(response.locations);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_GEOGRAPHIC_SCANS_BY_DATE({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.start);
      builder.setToDate(payload.end);
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      builder.setSearchAfter(payload.searchAfter);
      if (payload.scanType) {
        builder.setScanType(payload.scanType);
      }
      if (payload.id) {
        builder.addFilter(`${payload.analyticsType}Id`, EFilterOperators.EQ, payload.id);
      }

      ApiFactory.getScan()
        .getScansByDate(builder)
        .then((response) => {
          commit('UPDATE_SCANS_BY_DATE', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  }
};
