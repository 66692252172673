import { IAnalyticsScanTimeSeries } from '@/modules/analytics-report/analytics-report.interface';
import { IRootState } from '@/modules/common/common.interface';
import { EFilterOperators, IPaginatedData } from '@/modules/common/pagination.interface';
import Api from '@/services/api/ApiFactory';
import PaginatedResponse from '@/services/api/common/PaginatedResponse';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import ResourceResponse from '@/services/api/common/ResourceResponse';
import { IAnalyticsPayload } from '@/views/private/dashboard/types/interfaces';
import {
  IAnalyticsScanMetrics,
  IPopularProduct,
  IScanLocationCount,
  ISearchableScan
} from '@connect-saas/types';
import { ActionContext, ActionTree } from 'vuex';
import { IMiscState } from './types';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

// eslint-disable-next-line import/prefer-default-export
export const actions: ActionTree<IMiscState, IRootState> = {
  GET_PAGINATED_MISC_SCANS({ commit, dispatch }: ActionContext<IMiscState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.from);
      builder.setToDate(payload.to);
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);
      if (payload.scanType) {
        builder.setScanType(payload.scanType);
      }
      if (payload.id) {
        builder.addFilter(`${payload.analyticsType}Id`, EFilterOperators.EQ, payload.id);
      }
      ApiFactory.getScan()
        .getScansByDate(builder)
        .then((response: PaginatedResponse<IPaginatedData<ISearchableScan>>) => {
          commit('UPDATE_PAGINATED_MISC_SCAN', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_STAT_COUNTS({ commit, dispatch }: ActionContext<IMiscState, IRootState>, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanStat(payload)
        .then((response: IAnalyticsScanMetrics) => {
          commit('UPDATE_ANALYTICS_STAT_COUNTS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCAN_LOCATIONS(
    { commit, dispatch }: ActionContext<IMiscState, IRootState>,
    payload
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getScannedAtByLocation(payload)
        .then((response: { locations: IScanLocationCount[] }) => {
          commit('UPDATE_ANALYTICS_SCAN_LOCATIONS', response.locations);
          resolve(response.locations);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_MOST_SCANNED_PRODUCTS(
    { commit, dispatch }: ActionContext<IMiscState, IRootState>,
    payload
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanTopProducts(payload)
        .then((response: { data: IPopularProduct[] }) => {
          commit('UPDATE_ANALYTICS_MOST_SCANNED_PRODUCTS', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  },
  GET_ANALYTICS_SCAN_TIME_SERIES(
    { commit, dispatch }: ActionContext<IMiscState, IRootState>,
    payload: IAnalyticsPayload
  ) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanTimeSeries(payload)
        .then((response: ResourceResponse<IAnalyticsScanTimeSeries>) => {
          commit('UPDATE_ANALYTICS_SCAN_TIME_SERIES', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse, { root: true });
          reject(errorResponse);
        });
    });
  }
};
