import { IAnalyticsScanStat } from '@/modules/analytics/technology/technology.interface';
import { IRootState } from '@/modules/common/common.interface';
import { ITimeSeries } from '@/modules/common/service.type';
import {
  ICampaignList,
  IDemographics,
  IProductList
} from '@/modules/dashboard/dashboard.interface';
import { DashboardState } from '@/store/modules/dashboard/types';
import { IAnalyticsScanMetrics, IPopularProduct } from '@connect-saas/types';
// eslint-disable-next-line import/order
import { GetterTree } from 'vuex';

// eslint-disable-next-line import/prefer-default-export
export const getters: GetterTree<DashboardState, IRootState> = {
  time_series_data(state): ITimeSeries {
    return state.time_series_data;
  },
  statistics_data(state): IAnalyticsScanMetrics {
    return state.statistics_data;
  },
  most_scanned_products_data(state): IPopularProduct[] {
    return state.most_scanned_products_data;
  },
  geographics_data(state): IAnalyticsScanStat {
    return state.geographics_data;
  },
  demographics_data(state): IDemographics {
    return state.demographics_data;
  },
  most_engaged_products_data(state): IProductList {
    return state.most_engaged_products_data;
  },
  most_engaged_campaigns_data(state): ICampaignList {
    return state.most_engaged_campaigns_data;
  }
};
