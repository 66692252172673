/* eslint-disable import/order */
import { IRootState } from '@/modules/common/common.interface';
import { actions } from '@/store/modules/dashboard/actions';
import { getters } from '@/store/modules/dashboard/getters';
import { mutations } from '@/store/modules/dashboard/mutations';
import { DashboardState } from '@/store/modules/dashboard/types';
import { Module } from 'vuex';

const state: DashboardState = {
  time_series_data: {
    scans: [],
    authed: []
  },
  statistics_data: {
    scans: 0,
    authed: 0,
    invalidProduct: 0,
    invalidProtectCode: 0
  },
  most_scanned_products_data: [],
  geographics_data: {
    dimensions: {}
  },
  demographics_data: {
    gender: { male: 0, female: 0 },
    ageRange: []
  },
  most_engaged_products_data: {
    resources: [],
    meta: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 6,
      totalPages: 1
    }
  },
  most_engaged_campaigns_data: {
    resources: [],
    meta: {
      currentPage: 1,
      itemsPerPage: 10,
      totalItems: 6,
      totalPages: 1
    }
  }
};

// eslint-disable-next-line import/prefer-default-export
export const dashboard: Module<DashboardState, IRootState> = {
  state,
  getters,
  mutations,
  actions
};
