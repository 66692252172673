import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { mutations } from './mutations';
import { AnalyticsState } from './types';

const state: AnalyticsState = {
  selected_tech: null,
  selected_product: null,
  selected_campaign: null,
  analytics_scan_dimensions: null,
  analytics_scan_locations: null,
  analytics_scans_timeline: null,
  analytics_scans: null,
  analytics_campaign_stats: null,
  analytics_scan_metrics: null
};

// eslint-disable-next-line import/prefer-default-export
export const analytics: Module<AnalyticsState, IRootState> = {
  namespaced: true,
  state,
  mutations,
  actions
};
