import {
  EScanAuthFailureReason,
  EScanAuthStatus,
  EScanAuthType,
  EScanEventType,
} from '../../enum';
import { ITrackingLocation } from '../location.interface';
import { IUserAgent } from '../user-agent.interface';
import { IScanDevice, TSegmentMetadata } from '../domain-layer';
import { ETechMainType, ETechSubType } from './legacy-tech.interface';
import { TTechMetadata } from './tech.interface';

export enum EScanModels {
  SCAN = 'Scan',
  FORM_SUBMISSION = 'FormSubmission',
  LEAD = 'Lead',
  VISITOR = 'Visitor',
}

export enum EScanIndexes {
  GET_BY_VISITOR = 'gsByVisitor',
  GET_BY_ID = 'gsById',
  GET_BY_SCAN = 'gsByScan',
}

export enum EScanType {
  CAMPAIGN = 'campaign',
  CONNECT_LINK = 'connect_link',
  TRACER = 'tracer',
  MOTIFMICRO = 'motifmicro',
}

export interface IScanAuthStatus {
  state: EScanAuthStatus;
  failureReason?: EScanAuthFailureReason;
  protectCodeAttempts?: number;
}

export interface IScanAuthSettings {
  types: EScanAuthType[];
  protectCodeAttemptsAllowed: number;
}

export interface IScanAuth {
  settings?: IScanAuthSettings;
  status: IScanAuthStatus;
}

export interface IScanTrackingInfo extends ITrackingLocation {
  geo?: ITrackingLocation;
  ip?: ITrackingLocation;
}

export interface IScanCampaign {
  id: string;
  name: string;
  type: string;
}

export interface IScanCompany {
  id: string;
  name: string;
}

export interface IScanProduct {
  id: string;
  name: string;
  code: string;
}

export interface IScanSegment {
  id: string;
  name: string;
  metadata?: TSegmentMetadata;
}

export interface IScanTech {
  id: string;
  mainTech: ETechMainType;
  metadata?: TTechMetadata;
  scanUrl?: string;
  subTech?: ETechSubType;
}

export interface ILegacyScanVisitor {
  id: string;
}

export interface IScanWallet {
  id: string;
  name: string;
  type: string;
}

export interface IScanEvents {
  [EScanEventType.INVALID_PRODUCT_REPORT]?: string;
  [EScanEventType.INVALID_PROTECTCODE_REPORT]?: string;
  [EScanEventType.PRODUCT_VALIDATION]?: string;
  [EScanEventType.PROTECTCODE]?: string;
  [EScanEventType.PROTECTCODE_RETRY]?: string;
}

export interface IScan {
  auth?: IScanAuth;
  campaign?: IScanCampaign;
  company: IScanCompany;
  createdAt: Date;
  deletedAt?: Date;
  device: IScanDevice;
  id: string;
  ipAddress?: string;
  location: IScanTrackingInfo;
  product?: IScanProduct;
  scanEvents?: IScanEvents;
  scannedAt: Date;
  segment?: IScanSegment;
  tech: IScanTech;
  type: EScanType;
  updatedAt?: Date;
  userAgent?: IUserAgent;
  userId?: string;
  visitor?: ILegacyScanVisitor;
  wallet?: IScanWallet;
}

export interface ILinkScannedAtDays {
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
}

export interface ILinkScannedAtHours {
  0: number;
  1: number;
  2: number;
  3: number;
  4: number;
  5: number;
  6: number;
  7: number;
  8: number;
  9: number;
  10: number;
  11: number;
  12: number;
  13: number;
  14: number;
  15: number;
  16: number;
  17: number;
  18: number;
  19: number;
  20: number;
  21: number;
  22: number;
  23: number;
}

export interface ILinkScannedAtAnalytics {
  hourOfDay: ILinkScannedAtHours;
  dayOfWeek: ILinkScannedAtDays;
}

export enum ELinkScanMetric {
  SCANS = 'scans',
  VISITORS = 'visitors',
  LOCATION_COUNTRIES = 'locationCountries',
  LOCATION_CITIES = 'locationCities',
  DEVICE_BROWSERS = 'deviceBrowsers',
  DEVICE_BRANDS = 'deviceBrands',
  DEVICE_MODELS = 'deviceModels',
}

export enum ELinkScanDimension {
  DEVICE_BROWSER = 'deviceBrowser',
  DEVICE_MODEL = 'deviceModel',
  DEVICE_OS = 'deviceOs',
  DEVICE_TYPE = 'deviceType',
  DEVICE_BRAND = 'deviceBrand',
  LOCATION_COUNTRY = 'locationCountry',
  LOCATION_CITY = 'locationCity',
  LINK_TYPE = 'linkType',
  LINK_CONFIG = 'linkConfig',
  REFERRER_DOMAIN = 'referrerDomain',
  REFERRER_URL = 'referrerUrl',
}

export interface ILinkScanMetricsAnalytics {
  [ELinkScanMetric.SCANS]?: number;
  [ELinkScanMetric.VISITORS]?: number;
  [ELinkScanMetric.LOCATION_COUNTRIES]?: number;
  [ELinkScanMetric.LOCATION_CITIES]?: number;
  [ELinkScanMetric.DEVICE_BROWSERS]?: number;
  [ELinkScanMetric.DEVICE_BRANDS]?: number;
  [ELinkScanMetric.DEVICE_MODELS]?: number;
}

export interface ILinkScanPopularTermsStats {
  name: string;
  scans: number;
}
export interface ILinkScanPopularTermsData {
  scans: number;
  stats: ILinkScanPopularTermsStats[];
}
export interface ILinkScanPopularAnalytics {
  [ELinkScanDimension.DEVICE_BRAND]?: ILinkScanPopularTermsData;
  [ELinkScanDimension.DEVICE_BROWSER]?: ILinkScanPopularTermsData;
  [ELinkScanDimension.DEVICE_MODEL]?: ILinkScanPopularTermsData;
  [ELinkScanDimension.DEVICE_OS]?: ILinkScanPopularTermsData;
  [ELinkScanDimension.DEVICE_TYPE]?: ILinkScanPopularTermsData;
  [ELinkScanDimension.LINK_CONFIG]?: ILinkScanPopularTermsData;
  [ELinkScanDimension.LINK_TYPE]?: ILinkScanPopularTermsData;
  [ELinkScanDimension.LOCATION_CITY]?: ILinkScanPopularTermsData;
  [ELinkScanDimension.LOCATION_COUNTRY]?: ILinkScanPopularTermsData;
}
