/* eslint-disable import/order */
/* eslint-disable import/prefer-default-export */
import { IAnalyticsScanStat } from '@/modules/analytics/technology/technology.interface';
import { IRootState } from '@/modules/common/common.interface';
import Api from '@/services/api/ApiFactory';
import PaginationBuilder from '@/services/api/common/PaginationBuilder';
import { DashboardState } from '@/store/modules/dashboard/types';
import { ActionTree } from 'vuex';

const ApiFactory = new Api();
const builder = new PaginationBuilder();

export const actions: ActionTree<DashboardState, IRootState> = {
  GET_TIME_SERIES_DATA({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanTimeSeries(payload)
        .then((response) => {
          commit('UPDATE_TIME_SERIES_DATA', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_STATISTICS_DATA({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanStat(payload)
        .then((response) => {
          commit('UPDATE_STATISTICS_DATA', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_MOST_SCANNED_PRODUCTS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanTopProducts(payload)
        .then((response) => {
          commit('UPDATE_MOST_SCANNED_PRODUCTS_DATA', response.data);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_MOST_POPULAR_COUNTRIES_AND_CITIES({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ApiFactory.getAnalyticsScan()
        .getAnalyticsScanDimensions(payload)
        .then((response: { dimensions: IAnalyticsScanStat }) => {
          commit('UPDATE_GEOGRAPHICS_DATA', response.dimensions);
          resolve(response.dimensions);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_DEMOGRAPHICS_BY_GENDER_AND_AGE({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      ApiFactory.getDemography()
        .getGenderAndAgeRange()
        .then((response) => {
          commit('UPDATE_DEMOGRAPHICS_DATA', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_MOST_ENGAGED_PRODUCTS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.from);
      builder.setToDate(payload.to);
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);

      ApiFactory.getEngagementProducts()
        .getPaginatedEngagedProducts(builder)
        .then((response) => {
          commit('UPDATE_MOST_ENGAGED_PRODUCTS_DATA', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  },
  GET_MOST_ENGAGED_CAMPAIGNS({ commit, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      builder.setFromDate(payload.from);
      builder.setToDate(payload.to);
      builder.setPage(payload.page);
      builder.setPageSize(payload.itemsPerPage);

      ApiFactory.getEngagementCampaigns()
        .getPaginatedCampaigns(builder)
        .then((response) => {
          commit('UPDATE_MOST_ENGAGED_CAMPAIGNS_DATA', response);
          resolve(response);
        })
        .catch((errorResponse) => {
          dispatch('THROW_CUSTOM_ERROR', errorResponse);
          reject(errorResponse);
        });
    });
  }
};
