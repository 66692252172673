import { IPage, IPageCreate, IPageUpdate } from '@connect-saas/types';
import { IPaginatedData } from '@connect-saas/util/common';
import ClientInterface from './common/ClientInterface';
import ResourceResponse from './common/ResourceResponse';
import PaginationBuilder from './common/PaginationBuilder';
import PaginatedResponse from './common/PaginatedResponse';
import { ISearchablePage } from '@connect-saas/modules/search';

export default class Page {
  private readonly client: ClientInterface;

  private readonly resource = 'page';

  constructor(client: ClientInterface) {
    this.client = client;
  }

  createPage(body: IPageCreate): Promise<ResourceResponse<IPage>> {
    return this.client.create<IPageCreate, IPage>(this.resource, body);
  }

  updatePage(id: string, body: IPageUpdate): Promise<ResourceResponse<IPage>> {
    return this.client.patch(this.resource, body, id);
  }

  deletePage(id: string): Promise<void> {
    return this.client.delete(this.resource, id);
  }

  getPaginatedPages(
    builder: PaginationBuilder = new PaginationBuilder()
  ): Promise<PaginatedResponse<IPaginatedData<ISearchablePage>>> {
    builder.setResource(this.resource);
    return this.client.getPaginated<IPaginatedData<ISearchablePage>>(builder);
  }

  getPageById(id: string): Promise<ResourceResponse<IPage | null>> {
    return this.client.getById(this.resource, id);
  }

  duplicatePage(id: string): Promise<ResourceResponse<IPage>> {
    return this.client.post(`${this.resource}/${id}/duplicate`);
  }
}
