// eslint-disable-next-line import/order
import {
  IAnalyticsReport,
  IAnalyticsReportFilterLookups,
  IAnalyticsScanStats,
  IAnalyticsScanTimeSeries,
  ISearchableAnalyticsReport
} from '@/modules/analytics-report/analytics-report.interface';
import { IPaginatedData } from '@/modules/common/pagination.interface';
import { IScanLocationCount } from '@connect-saas/types';
import { MutationTree } from 'vuex';
import { AnalyticsReportState } from './types';
import { IPaginatedScansV3 } from '@connect-saas/modules/scan-search';

// eslint-disable-next-line import/prefer-default-export
export const mutations: MutationTree<AnalyticsReportState> = {
  UPDATE_ANALYTICS_REPORT(
    state,
    update_analytics_report: IPaginatedData<ISearchableAnalyticsReport>
  ) {
    state.update_analytics_report = update_analytics_report;
  },
  SELECTED_ANALYTICS_REPORT(state, selected_analytics_report: IAnalyticsReport) {
    state.selected_analytics_report = selected_analytics_report;
  },
  SELECTED_ANALYTICS_REPORT_EXPORT(state, selected_analytics_report_export: IAnalyticsReport) {
    state.selected_analytics_report_export = selected_analytics_report_export;
  },
  SELECTED_ANALYTICS_REPORT_SCANS_COMPONENT(
    state,
    selected_analytics_report_scans_component: IPaginatedScansV3
  ) {
    state.selected_analytics_report_scans_component = selected_analytics_report_scans_component;
  },
  SELECTED_ANALYTICS_REPORT_SCAN_LOCATIONS(
    state,
    selected_analytics_report_scan_locations: IScanLocationCount
  ) {
    state.selected_analytics_report_scan_locations = selected_analytics_report_scan_locations;
  },
  SELECTED_ANALYTICS_REPORT_SCAN_TIME_SERIES(
    state,
    selected_analytics_report_scan_time_series: IAnalyticsScanTimeSeries
  ) {
    state.selected_analytics_report_scan_time_series = selected_analytics_report_scan_time_series;
  },
  SELECTED_ANALYTICS_REPORT_SCAN_STATS(
    state,
    selected_analytics_report_scan_stats: IAnalyticsScanStats
  ) {
    state.selected_analytics_report_scan_stats = selected_analytics_report_scan_stats;
  },
  UPDATE_ANALYTICS_REPORT_FILTER_LOOKUPS(
    state,
    update_analytics_report_filter_lookups: IAnalyticsReportFilterLookups
  ) {
    state.update_analytics_report_filter_lookups = update_analytics_report_filter_lookups;
  },
  CLREAR_ANALYTICS_REPORT_DATA(state) {
    state.selected_analytics_report = null;
    state.selected_analytics_report_scans_component = null;
    state.selected_analytics_report_scan_locations = null;
    state.selected_analytics_report_scan_time_series = null;
    state.selected_analytics_report_scan_stats = null;
  }
};
