import { Module } from 'vuex';
import { IRootState } from '@/modules/common/common.interface';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';
import { GeographyState } from './types';

const state: GeographyState = {
  scans_by_location: null,
  scans_by_date: null
};

// eslint-disable-next-line import/prefer-default-export
export const geography: Module<GeographyState, IRootState> = {
  state,
  getters,
  mutations,
  actions
};
